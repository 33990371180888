














































import {Component, Prop, Vue} from "vue-property-decorator";



@Component({
  components: {}
})
export default class VoiceRecorder extends Vue {
  @Prop({required: false, default: false})
  isChatMessage!: boolean

  recordedData: Object | null = null;
  sendButton: boolean = false;
  recordedUrl: string | null = null;

  currentlyRecording: boolean = false;

  device: any | null = null;
  items: Array<any> = [];
  recorder: any | null = null;

  sendRecording(){
    let d = new Date();
    //@ts-ignore
    let recordFile = new File([this.recordedData], 'record-'+d.getTime()+'.webm', {type: 'audio/webm', lastModified: Date.now()});
    //@ts-ignore
    this.$emit('recorded-file', {file: recordFile});
    //@ts-ignore
    this.$parent.close();
  }


  deleteRecord(){
    this.recordedData = null;
    this.recordedUrl = null;
  }

  startDeviceMedia() {

  }

  mounted(){
    this.device = navigator.mediaDevices.getUserMedia({audio: true});
    this.device.then( (stream: any) => {
      //@ts-ignore
      window.localStream=stream;
      //@ts-ignore
      this.recorder = new MediaRecorder(stream);
      this.recorder.ondataavailable = (e:any) => {
        this.items.push(e.data);
        if(this.recorder.state == 'inactive'){
          this.recordedData = new Blob(this.items, {type: 'audio/webm'} );
          this.recordedUrl = URL.createObjectURL(this.recordedData);
        }
      }
    })
  }

  startRecoding(){
    this.currentlyRecording = true;
    this.recorder.start();
  }
  stopRecoding(){
    this.currentlyRecording = false;
    this.recorder.stop();
  }

  destroyed() {
    //@ts-ignore
    window.localStream.getTracks()[0].stop();
  }
}
